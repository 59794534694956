import { AuthenticatedTemplate, useMsal } from '@azure/msal-react'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import {
  Avatar,
  Box,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import MuiDrawer from '@mui/material/Drawer'
import {
  CSSObject,
  SxProps,
  Theme,
  styled,
  useTheme,
} from '@mui/material/styles'
import { Container } from '@mui/system'
import logo from 'assets/img/RTS_logo_big.png'
import NotificationBar from 'components/atoms/NotificationBar'
import { drawerMenu, settingsMenu } from 'data/app-consts'
import { useAtom } from 'jotai'
import { ReactNode, useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { Navigate } from 'react-router-dom'
import { alertAtom } from 'stores'
import {
  accessTokenAtom,
  idTokenAtom,
  isDrawerOpen,
  logout,
  userAtom,
} from 'stores/auth'
import { loginRequest } from 'authConfig'

const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(6)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(6)} + 1px)`,
  },
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '0 .5rem',
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,

  minHeight: '3rem!important',
}))

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  width: `calc(100% - ${theme.spacing(6)} - 1px)`,
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}))

const AdminPageWrapper = ({
  pageTitle,
  children,
  sx,
}: {
  pageTitle: string
  children: ReactNode
  sx?: SxProps<Theme>
}): JSX.Element => {
  const theme = useTheme()
  const { instance } = useMsal()

  const [open, setOpen] = useAtom(isDrawerOpen)
  const navigate = useNavigate()

  const [, setIdToken] = useAtom(idTokenAtom)
  const [, setAccessToken] = useAtom(accessTokenAtom)
  const [user] = useAtom(userAtom)

  useEffect(() => {
    async function checkAuth(): Promise<JSX.Element | undefined> {
      const activeAccount = instance.getActiveAccount()
      if (activeAccount) {
        try {
          const response = await instance.acquireTokenSilent(loginRequest)
          setAccessToken(response.accessToken)
          setIdToken(response.idToken)
        } catch (error: any) {
          console.error('Token refresh failed:', error)

          // Check if error requires re-authentication
          if (error.name === 'InteractionRequiredAuthError') {
            try {
              // Force interactive login if silent renewal fails
              const response = await instance.acquireTokenPopup(loginRequest)
              console.log('New Access Token:', response.accessToken)
              setAccessToken(response.accessToken)
            } catch (interactiveError) {
              console.error('Interactive login failed:', interactiveError)
              return <Navigate to="/" state={{ returnTo: location }} />
            }
          } else {
            return <Navigate to="/" state={{ returnTo: location }} />
          }
        }
      } else {
        return <Navigate to="/" state={{ returnTo: location }} />
      }
    }

    checkAuth()
  }, [instance])

  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null)
  const [alert, setAlert] = useAtom(alertAtom)

  const handleNotificationClose = (): void => {
    setAlert(null)
  }

  const handleDrawerOpen = (): void => {
    setOpen(true)
  }

  const handleDrawerClose = (): void => {
    setOpen(false)
  }

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorElUser(event.currentTarget)
  }

  const handleCloseUserMenu = (path?: string): void => {
    setAnchorElUser(null)
    if (path) {
      navigate(path)
    }
  }

  const handleLogout = (): void => {
    instance.logoutRedirect()
    logout()
  }

  return (
    <Box
      component="div"
      sx={{ display: 'flex' }}
      className="ir_admin_Page_wrapper"
    >
      <AppBar
        position="fixed"
        open={open}
        elevation={0}
        sx={{ backgroundColor: 'white' }}
      >
        <Toolbar color="secondary" sx={{ minHeight: '3rem!important' }}>
          <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                color: theme.palette.primary.dark,
                marginRight: 2,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="open drawer"
              onClick={handleDrawerClose}
              edge="start"
              sx={{
                color: theme.palette.primary.main,
                marginRight: 5,
                ...(!open && { display: 'none' }),
              }}
            >
              <MenuOpenIcon />
            </IconButton>
            <Typography
              data-testid="app-title"
              variant="h5"
              noWrap
              color="black"
              component="h1"
            >
              User Management
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <AuthenticatedTemplate>
              <Tooltip title="Open settings">
                <IconButton
                  data-testid="settings-menu"
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0, color: theme.palette.secondary.dark }}
                >
                  <Avatar
                    alt={user?.firstName ? user.firstName : ''}
                    src={''}
                    sx={{ width: 28, height: 28 }}
                  />
                </IconButton>
              </Tooltip>
            </AuthenticatedTemplate>
            <Menu
              sx={{ mt: '2.1875rem' }}
              id="menu-appbar"
              data-testid="appbar-sidenav-menu"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={(): void => handleCloseUserMenu()}
            >
              {settingsMenu.map(setting => (
                <MenuItem
                  data-testid={`settings-menu-${setting.text
                    .toLowerCase()
                    .split(' ')
                    .join('-')}`}
                  key={setting.text}
                  onClick={(): void => handleCloseUserMenu(setting.path)}
                >
                  <Typography textAlign="center">{setting.text}</Typography>
                </MenuItem>
              ))}
              <MenuItem
                onClick={handleLogout}
                data-testid="settings-menu-log-out"
              >
                <Typography textAlign="center">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        open={open}
        PaperProps={{
          sx: {
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.text.secondary,
          },
        }}
      >
        <DrawerHeader>
          <NavLink to="/" style={{ outline: 'none' }}>
            <img
              src={logo}
              style={{ height: open ? '2.0625rem' : '1.5625rem' }}
            />
          </NavLink>
        </DrawerHeader>
        <Divider />
        <List>
          {drawerMenu.map(menu => (
            <ListItem key={menu.text} disablePadding sx={{ display: 'block' }}>
              <NavLink
                to={menu.path}
                end={menu.end}
                style={{ textDecoration: 'none', color: 'inherit' }}
                data-testid={`drawer-menu-${menu.text
                  .toLowerCase()
                  .split(' ')
                  .join('-')}`}
              >
                {({ isActive }): JSX.Element => (
                  <ListItemButton
                    sx={{
                      justifyContent: open ? 'initial' : 'center',
                      px: 1,
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 164, 118, 0.2)',
                      },
                    }}
                    selected={isActive ? true : false}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <Tooltip
                        title={!open ? menu.text : ''}
                        arrow
                        placement="right"
                      >
                        <Icon
                          sx={{
                            color: isActive
                              ? theme.palette.primary.main
                              : theme.palette.secondary.dark,
                          }}
                        >
                          {menu.icon}
                        </Icon>
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText
                      primary={menu.text}
                      sx={{ opacity: open ? 1 : 0 }}
                      primaryTypographyProps={{
                        fontSize: '0.9rem',
                        color: isActive
                          ? theme.palette.primary.light
                          : theme.palette.secondary.dark,
                      }}
                    />
                  </ListItemButton>
                )}
              </NavLink>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Box
        component="main"
        sx={[
          {
            flexGrow: 1,
            p: '0 1.25rem 1.25rem 1.25rem',
            backgroundColor: '#F5F7FA',
            minHeight: '100vh',
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <DrawerHeader />
        <Container
          maxWidth={false}
          data-testid="admin-page-wrapper-container"
          className="admin-page-wrapper-container"
          sx={{ p: '0!important', mt: 2 }}
        >
          <Typography
            data-testid="page-title"
            variant="title"
            component="h1"
            sx={{ mb: 2 }}
          >
            {pageTitle}
          </Typography>
          {alert !== null ? (
            <NotificationBar
              open={alert.show}
              message={alert.message}
              type={alert.type}
              autoHideDuration={alert.autoHideDuration || null}
              sx={{ mb: '2.1875rem' }}
              onCloseCB={handleNotificationClose}
            />
          ) : null}
          {children}
        </Container>
      </Box>
    </Box>
  )
}

export default AdminPageWrapper
