import {
  AuthenticationResult,
  EventMessage,
  EventType,
  PublicClientApplication,
} from '@azure/msal-browser'
import '@datadog/browser-rum/bundle/datadog-rum'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import App from 'App'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { msalConfig } from 'authConfig'
import reportWebVitals from 'reportWebVitals'
import theme from 'theme'

import './styles/index.scss'

export const msalInstance = new PublicClientApplication(msalConfig)

msalInstance.initialize().then(() => {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  const accounts = msalInstance.getAllAccounts()
  if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0])
  }

  msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult
      const account = payload.account
      msalInstance.setActiveAccount(account)
    }
  })

  const root = createRoot(document.getElementById('root') as HTMLElement)
  root.render(
    //<StrictMode>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <BrowserRouter>
        <App instance={msalInstance} />
      </BrowserRouter>
    </ThemeProvider>
    //</StrictMode>
  )
})

reportWebVitals()
